<template>
  <div class="pa-4">
    <v-container fluid>
      <h2 text color="black" class="headline">受付記録</h2>
      <h3 text color="black" class="mt-2">受付心電計一覧</h3>
      <v-row dense>
        <v-col
          cols="12" md="12" sm="12" xl="12"
        >
          <v-toolbar
            rounded
            color="primary"
            flat
            dark
            class="pb-3 pl-2 pr-2 pt-4"
            height="auto"
          >
            <v-row dense>
              <v-col cols="12" sm="3">
                <v-select
                  class="mt-3"
                  dense
                  hide-details
                  eager
                  v-model="searchSelectedField"
                  label="検索フィールド"
                  :items="searchFields"
                  @change="subscribeItems"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  dense
                  label="前方一致検索キーワード"
                  outlined
                  clearable
                  hide-details
                  v-model="searchKeyword"
                  prepend-inner-icon="search"
                  @blur="subscribeItems"
                  @keyup.enter="subscribeItems"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="5" style="display: flex; justify-content: flex-end; align-items: center;">
                <div class="text-caption mr-2">選択したデバイスに対する一括処理</div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      small
                      icon
                      @click="disposeSelectedItem()"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon class="mx-3">delete</v-icon>
                    </v-btn>
                  </template>
                  <span>一括廃棄記録</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox
                  dense
                  v-model="notReceivedDeviceFlag"
                  label="心電計未受付"
                  @change="subscribeItems"
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox
                  dense
                  v-model="notReceivedActivityLogFlag"
                  label="行動記録用紙未受付"
                  @change="subscribeItems"
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-checkbox
                  dense
                  v-model="uploadErrorFlag"
                  label="アップロードエラー"
                  @change="subscribeItems"
                  hide-details
                />
              </v-col>
            </v-row>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync=selectedLinesPerPage
            hide-default-footer
            show-select
            v-model="selected"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            fixed-header
            height="500"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-data-footer
                :pagination="pagination"
                :options="options"
                @update:options="updateOptions"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                :items-per-page-options="linesPerPageList"
                class="border-less"
              />
            </template>
            <!-- 操作 -->
            <template v-slot:[`item.action`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="viewItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">remove_red_eye</v-icon>
                  </v-btn>
                </template>
                <span>詳細表示</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="editItemForm(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">edit</v-icon>
                  </v-btn>
                </template>
                <span>編集</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="disposeItem(item)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mx-3">delete</v-icon>
                  </v-btn>
                </template>
                <span>廃棄記録</span>
              </v-tooltip>
            </template>
            <!-- 状態 -->
            <template v-slot:[`item.status`]="{ item }">
              <device-status-chip :item="item" />
            </template>
            <!-- 心電計アップロード結果 -->
            <template v-slot:[`item.device_upload_result`]="{ item }">
              <div class="red--text" v-if="item.device_upload_result=='error'">
                ×
                <v-btn icon color="red" @click="$root.$alert('エラー', item.device_upload_error_info.msg );">
                  <v-icon small>mdi-message-text-outline</v-icon>
                </v-btn>
              </div>
              <div v-else>{{ item.device_upload_result }}</div>
            </template>
            <!-- 行動記録用紙アップロード結果 -->
            <template v-slot:[`item.log_upload_result`]="{ item }">
              <div class="red--text" v-if="item.log_upload_result=='error'">
                ×
                <v-btn icon color="red" @click="$root.$alert('エラー', item.log_upload_error_info.msg );">
                  <v-icon small>mdi-message-text-outline</v-icon>
                </v-btn>
              </div>
              <div v-else>{{ item.log_upload_result }}</div>
            </template>
            <!-- 心電計型式 -->
            <template v-slot:item.ecg_type="{ item }">
              {{ ecgType2Text(item) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-spacer />
        </v-col>
      </v-row>
      <v-row>
        <v-fab-transition>
          <v-btn
            id="rb-fab"
            color="red"
            large
            dark
            absolute
            right
              fab
            class="rb-fab"
            @click="collectItemForm"
          >
            受付記録
          </v-btn>
        </v-fab-transition>
      </v-row>
    </v-container>

    <inventory-dialog
      v-if="mode != 'none' && currentItem != null"
      v-model="inventoryDialogModel"
      :mode="mode"
      :item="currentItem"
      :dialog-title="dialogTitle"
      @closed="onDialogClosed"
      @not-deleted="itemNotDeleted"
    />

  </div>
</template>

<script>
import dateformat from 'dateformat';
import InventoryDialog from '@/components/InventoryDialog.vue';
import DeviceStatusChip from '@/components/DeviceStatusChip.vue';

let unsubscribeItems = null;
let itemsRef = null;

const UPLOAD_RESULT = {
  OK: '○',
  NG: 'error',
  NONE: null,
};


export default {
  name: 'Collections',
  components: {
    InventoryDialog,
    DeviceStatusChip,
  },
  mixins:[
  ],
  data() {
    return {
      itemsName: 'devices',
      inventoryDialogModel: false,
      headers: [
        {
          text: '操作',
          align: 'left',
          sortable: false,
          value: 'action',
          width: '140px',
        },
        {
          text: '受付日時',
          align: 'left',
          sortable: true,
          value: 'collected',
          width: '150px',
        },
        {
          text: 'デバイスID',
          align: 'left',
          sortable: true,
          value: 'device_id',
          width: '110px',
        },
        {
          text: '型式',
          align: 'left',
          sortable: true,
          value: 'ecg_type',
          width: '80px',
        },
        {
          text: '状態',
          align: 'left',
          sortable: true,
          value: 'status',
          width: '120px',
        },
        {
          text: '心-受付',
          align: 'left',
          sortable: true,
          value: 'device_collected',
          width: '150px',
        },
        {
          text: '行-受付',
          align: 'left',
          sortable: true,
          value: 'log_collected',
          width: '150px',
        },
        {
          text: '心-UP日時',
          align: 'left',
          sortable: true,
          value: 'device_uploaded',
          width: '150px',
        },
        {
          text: '結果',
          align: 'left',
          sortable: true,
          value: 'device_upload_result',
          width: '80px',
        },
        // {
        //   text: '使用期限日',
        //   align: 'left',
        //   sortable: true,
        //   value: 'expiration_date',
        //   width: '130px',
        // },
      ],
      emailRules: [
        v => !!v || '入力は必須です。',
        v => /.+@.+\..+/.test(v) || 'Emailの形式が正しくありません。',
      ],
      nameRules: [
        v => !!v || '入力は必須です。',
      ],
      postalCodeRules: [
        v => !!v || '入力は必須です。',
      ],
      prefRules: [
        v => !!v || '入力は必須です。',
      ],
      cityRules: [
        v => !!v || '入力は必須です。',
      ],
      uniqueIdRules: [],
      remarksRules: [],
      items: [],
      currentItem: {},
      mode: 'none',
      recordDialogTitle: '登録',
      configRobots: {},
      modelNumbers: [],
      searchKeyword: '',
      accessTypeModel: 'all',
      // 上書きされる可能性のあるプロパティ
      searchSelectedField: 'デバイスID',
      searchFields: ['デバイスID'],
      statusNames: [
        '入荷済', '発送済み', '販売スタッフが受領済み', '未使用',
        '使用中', '受付センター着荷', '解析中（アップロード済み）', '解析完了',
        '未使用期限切れ',
        '廃棄済み',
        '紛失'],
      typeNames: {
        holter: 'ホルター心電計',
        dongle: '通信用ドングル／ソフト'
      },
      fab: true,
      dialogTitle: '',
      type: 'holter',
      selectedLinesPerPage: 10,
      linesPerPageList: [10, 30, 50],
      receptionStatusCode: 5,
      selected: [],
      notReceivedDeviceFlag: false,
      notReceivedActivityLogFlag: false,
      uploadErrorFlag: false,
      sortBy: ['collected'],
      sortDesc: [true],
    };
  },
  computed: {
    logDateFormatted() {
      return this.logDate.toLocaleDateString().replace(/\//g, '-');
    },
  },
  methods: {
    collectItemForm() {
      console.log('collectItemForm');
      this.mode = 'collect';
      this.currentItem = {};
      this.dialogTitle = '受付記録';
      this.inventoryDialogModel = true;
    },

    viewItemForm(item) {
      this.mode = 'view';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    editItemForm(item) {
      this.mode = 'edit';
      this.currentItem = item;
      this.inventoryDialogModel = true;
      this.dialogTitle = 'デバイス情報';
    },
    deleteItem(item) {
      this.mode = 'delete';
      this.currentItem = item;
    },
    onDialogClosed() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      this.currentItem = null;
      this.inventoryDialogModel = false;
    },
    itemNotDeleted() {
      // 削除完了後に呼び出されるCallback
      this.mode = 'none';
      // console.log('item-not-deleted');
    },
    timestampToString(timestamp, format='yyyy/mm/dd HH:MM'){
      try {
        return timestamp ? dateformat(new Date(timestamp.seconds * 1000), format): null;
      } catch (e) {
        return null;
      }
    },
    subscribeItems() {
      itemsRef = this.$db.collection(`${this.itemsName}`);
      // 購読中は一旦解除した上で対応する。
      if (unsubscribeItems) unsubscribeItems();
      this.searchKeyword = this.searchKeyword ? this.searchKeyword.trim(): '';

      let cRef = itemsRef
        .where('deleted', '==', null)
        .where('status', 'in', [5]);

      // 所属でフィルタ
      if (!this.$store.state.user.role.startsWith('management')) {
        // 運営主体でない場合、所属するセンターのデータのみ
        cRef = cRef.where('reception_center_id', '==', this.$store.state.user.org_id);
      }

      if (this.searchKeyword.length > 0) {
        // 検索キーワードで前方検索
        let startAt = this.searchKeyword;
        let endAt = this.searchKeyword + '\uffff';
        let searchFieldsValues = {
          'デバイスID':'device_id',
        };
        // 指定したキーで前方検索
        cRef = cRef
          .orderBy(searchFieldsValues[this.searchSelectedField])
          .startAt(startAt).endAt(endAt);
      }

      // 課金等を考慮した読み取り最大数の制限
      cRef = cRef.limit(this.$store.state.configs.query_limit);

      unsubscribeItems = cRef
        .onSnapshot((querySnapshot) => {
            // 最初および変更時の読み取り
            let items = [];
            let subscribedDeviceIds = [];
            querySnapshot.forEach((doc) => {
              let data = doc.data();
              if (data.deleted == null) {
                data.created = this.timestampToString(data.created);
                data.modified = this.timestampToString(data.modified);
                data.device_collected = this.timestampToString(data.device_collected);
                data.log_collected = this.timestampToString(data.log_collected);
                data.produced = this.timestampToString(data.produced, 'yyyy/mm/dd');
                data.expiration_date = this.timestampToString(data.expiration_date, 'yyyy/mm/dd');
                data.id = doc.id;

                // 心電図アップロード関連の編集
                if ('device_uploaded' in data && data.device_uploaded != null) {
                  data.device_uploaded = this.timestampToString(data.device_uploaded);
                  data.device_upload_result = UPLOAD_RESULT.OK;
                } else if (
                  'device_upload_error_info' in data && data.device_upload_error_info != null
                  && 'datetime' in data.device_upload_error_info && data.device_upload_error_info.datetime != null
                ) {
                  data.device_uploaded = this.timestampToString(data.device_upload_error_info.datetime);
                  data.device_upload_result = UPLOAD_RESULT.NG;
                } else {
                  data.device_uploaded = null;
                  data.device_upload_result = UPLOAD_RESULT.NONE;
                }

                // 行動記録用紙アップロード関連の編集
                if ('log_uploaded' in data && data.log_uploaded != null) {
                  data.log_uploaded = this.timestampToString(data.log_uploaded);
                  data.log_upload_result = UPLOAD_RESULT.OK;
                } else if (
                  'log_upload_error_info' in data && data.log_upload_error_info != null
                  && 'datetime' in data.log_upload_error_info && data.log_upload_error_info.datetime != null
                ) {
                  data.log_uploaded = this.timestampToString(data.log_upload_error_info.datetime);
                  data.log_upload_result = UPLOAD_RESULT.NG;
                } else {
                  data.log_uploaded = null;
                  data.log_upload_result = UPLOAD_RESULT.NONE;
                }

                data.collected = this.timestampToString(data.collected);

                if (!this.isHitExtendedSearchCondition(
                  this.notReceivedDeviceFlag,
                  this.notReceivedActivityLogFlag,
                  this.uploadErrorFlag,
                  data
                )) {
                  return;
                }

                subscribedDeviceIds.push(data.device_id);
                items.push(data);
              }
            });

            // 一覧に無い選択済みデバイスは選択を解除する
            this.selected = this.selected.filter((device) => {
              return subscribedDeviceIds.includes(device.device_id);
            });

            this.items = items.reverse();
            //console.log('onSnapshot', this.items);
        }, (e) => {
          console.log('error', e);
          this.items = [];
        });
    },
    isHitExtendedSearchCondition(
      notReceivedDeviceFlag,
      notReceivedActivityLogFlag,
      uploadErrorFlag,
      deviceData
    ) {
      /**
       * 心電計受付
       */
      if (notReceivedDeviceFlag == true
        && deviceData.device_collected !== null
      ) {
        return false
      }
      /**
       * 行動記録用紙受付
       */
      if (notReceivedActivityLogFlag == true
        && deviceData.log_collected !== null
      ) {
        return false
      }
      /**
       * アップロードエラー
       */
      const isError = (
        deviceData.device_upload_result === UPLOAD_RESULT.NG
        || deviceData.log_upload_result === UPLOAD_RESULT.NG
      );
      if (uploadErrorFlag === true && !isError) {
        return false;
      }

      return true;
    },
    updateLogDate() {
      // console.log('updateLogDate');
    },
    isSwitchingUser() {
      // superUserが存在しない場合は、なりすまし不可
      if (!this.$store.state.superUser) {
        return false;
      }
      // ログイン中のユーザーIDと、superUserのIDが同一の場合はなりすましではない
      if (this.$store.state.user.id == this.$store.state.superUser.id) {
        return false;
      }
      return true;
    },
    // 廃棄記録（一括）
    async disposeSelectedItem() {
      console.log('disposeSelectedItem', this.selected);
      const result = await this.$root.$confirm(`確認`, `選択された全てのデバイスに対して一括で廃棄記録しますか？`);
      if (!result) return;
      for (const item of this.selected) {
        await this.disposeItemBody(item);
      }
    },
    // 廃棄記録（個別）
    async disposeItem(item) {
      console.log('disposeItem', item);
      const result = await this.$root.$confirm(`確認`, `このデバイス ${item.device_id} の廃棄を記録しますか？`);
      if (!result) return;
      await this.disposeItemBody(item);
    },
    // 廃棄記録本体
    async disposeItemBody(item) {
      try {
        const deviceId = item.device_id;

        // デバイスデータ取得
        const docRef = this.$db.doc('devices/' + deviceId);
        const doc = await docRef.get();
        if (!doc.exists) {
          await this.$root.$alert('エラー', `そのようなデバイス ${deviceId} は存在しません。`);
          return;
        }
        const deviceData = doc.data();
        if (this.receptionStatusCode != deviceData.status) {
          await this.$root.$alert('エラー', `このデバイス ${deviceId} は受付されていません。`);
          return;
        }

        if (!deviceData.device_collected) {
          // 心電計の受付記録がされていない場合は、廃棄記録できない
          await this.$root.$alert('エラー', '心電計が受付されていないため廃棄記録できません。');
          return;
        }

        // デバイスデータ更新
        let updateData = {
          status: 9,
          disposed: this.$firebase.firestore.FieldValue.serverTimestamp(),
          disposed_staff_id: this.$store.state.user.id,
          modified:  this.$firebase.firestore.FieldValue.serverTimestamp(),
        }
        await docRef.update(updateData);
        await this.writeLog(['device_dispose'], `廃棄記録しました。`, item); // 操作記録

        // 該当デバイス受付センターの保管数を減らし、廃棄数を増やす
        const refReceptionCenter = this.$db.collection('staff_orgs').doc(deviceData.reception_center_id);
        await refReceptionCenter.update({ holter_stock_quantity: this.$firebase.firestore.FieldValue.increment(-1) });
        await refReceptionCenter.update({ holter_stock_quantity_disposed: this.$firebase.firestore.FieldValue.increment(1) });
      } catch (e) {
        console.error(e);
        this.$root.$alert('エラー', '廃棄記録に失敗しました。' + e);
      }
    },
    async writeLog(tags, message, item, option = null) {
      const deviceType = item.type == 'dongle' ? '通信用ドングル／ソフト' : 'ホルター心電計';
      message = `デバイス：${deviceType} (${item.device_id})：${message}`
      await this.$functions.log({ tags, message, item: option });
    },
    updateSortBy(v) {
      this.sortBy = v;
    },
    updateSortDesc(v) {
      this.sortDesc = v;
    },
    ecgType2Text(v) {
      if (/^T-[0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[1];
      }

      if (/^T-[A-Z][0-9]{6}$/.test(v.device_id)) {
        return this.$store.state.ecgTypes[2];
      }
      
      return '';
    },
  },
  mounted() {
    // テーブル表示件数の初期読み込み
    if (this.$store.state.user.table_lines_par_page) {
      this.selectedLinesPerPage = this.$store.state.user.table_lines_par_page;
    }
    // 他の画面で表示件数が変更された場合、こちらの画面にも反映させる
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setUserTableLinesParPage') {
        this.selectedLinesPerPage = state.user.table_lines_par_page;
      }
    });

    this.subscribeItems();

  },
  watch: {
    selectedLinesPerPage(lineNumber) {
      if (this.$store.state.user.table_lines_par_page != lineNumber) {
        const staffsRef = this.$db
          .collection('staffs')
          .doc(this.$store.state.user.id);
        // 運営主体管理ユーザーによる、なりすましの場合は更新しない
        if (!this.isSwitchingUser()) {
          staffsRef.update({
            table_lines_par_page: lineNumber
          });
        }
        this.$store.commit('setUserTableLinesParPage', lineNumber);
      }
    }
  },
  beforeDestroy() {
    // 変更監視を停止
    if (unsubscribeItems) unsubscribeItems();
  }
}
</script>

<style scoped>
  .theme-color {
    color: white;
    background-color:  #B3131A;
  }

  .v-speed-dial {
    position: absolute;
    top: 0.5em;
    right: 4em;

  }

  .v-btn--floating {
    position: relative;
  }

  .menu-buttons {
    position: absolute;
    top: 0.5em;
    right: 4em;
  }

  .menu-buttons button {
    margin-right: 1em;
  }

  .border-less {
    border: none !important;
  }
</style>